import React from "react";
import { images } from "../constants";

const Construction = () => {
	return (
		<div className='flex flex-col items-center justify-center h-screen bg-[#1e1c1c]'>
			<div className='p-2 rounded-md bg-green-200/30 backdrop-blur-sm w-[40%]'>
				<img src={images.logoPng} alt='logo' className=' animate-float ' />
			</div>

			<p className='text-white mt-2'> Under construction... </p>
		</div>
	);
};

export default Construction;
