import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing, Home, Mint, Staking } from "./components";
import { TransactionContextProvider } from "./hooks/useTx";
import { RecoilRoot } from "recoil";
import Construction from "./components/Construction";
import "animate.css";

const { chains, provider } = configureChains(
	[chain.goerli],
	[infuraProvider({ apiKey: process.env.INFURA_KEY }), publicProvider()]
);

const { connectors } = getDefaultWallets({
	appName: "My RainbowKit App",
	chains,
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
});

const App = () => {
	return (
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains}>
				<RecoilRoot>
					<TransactionContextProvider>
						<BrowserRouter>
							<Routes>
								<Route path='/' element={<Landing />} />
								<Route path='/home' element={<Home />} />
								<Route path='/mint' element={<Construction />} />
								<Route path='/staking' element={<Construction />} />
							</Routes>
						</BrowserRouter>
					</TransactionContextProvider>
				</RecoilRoot>
			</RainbowKitProvider>
		</WagmiConfig>
	);
};

export default App;
