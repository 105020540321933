import { atom } from "recoil";

export const statsState = atom({
	key: "stats",
	default: [Array(6).fill(0)],
});

export const totalState = atom({
	key: "total",
	default: [4],
});

export const amountState = atom({
	key: "amount",
	default: 1,
});

export const indexState = atom({
	key: "index",
	default: 0,
});

export const disabledState = atom({
	key: "mintDisabled",
	default: true,
});

export const selectedNFTsState = atom({
	key: "selectedNFTs",
	default: [],
});
