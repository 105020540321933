import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import { GiHamburgerMenu } from "react-icons/gi";
import { Menu } from "..";

const Navigation = ({ sticky }) => {
	const [active, setActive] = useState(false);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [menuOpen, setMenuOpen] = useState(false);
	const [width, setWidth] = useState("0px");

	const { innerWidth: inner, innerHeight: height } = window;
	const limit = height / 98;

	const toggleMenu = () => {
		setMenuOpen((prevState) => !prevState);
		setWidth(!menuOpen ? "0vw" : `${100}vw`);
	};

	const handleScroll = () => {
		if (typeof window !== "undefined") {
			const position = window.pageYOffset;

			setScrollPosition(position);

			if (window.scrollY > lastScrollY && !menuOpen) {
				// if scroll down hide the navbar
				setActive(true);
			} else {
				// if scroll up show the navbar
				setActive(false);
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		/* if (typeof window !== "undefined") {
			window.addEventListener("scroll", handleScroll, { passive: true });

			// cleanup function
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		} */
	}, [lastScrollY]);

	return (
		<div
			className={`${
				sticky ? "fixed  ease-in-out duration-200" : "fixed"
			} z-20 w-[100vw] top-0 left-0 ${
				active ? " -translate-y-full top-0" : ""
			}  h-16 sm:h-[15vh] max-w-[1500px] mx-auto bg-opacity-80 `}
		>
			<Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			<div
				className={` ${
					active ? "justify-end" : "justify-between"
				} flex flex-row items-center w-10/12 h-full mx-auto text-white`}
			>
				{/* <button
					className={`${
						active ? "hidden" : "flex"
					} w-10 h-10 items-center justify-center justify-self-start ease-in-out duration-300 group`}
					onClick={toggleMenu}>
					<GiHamburgerMenu className='sm:h-10 sm:w-10 h-6 w-6 group-focus:rotate-90 z-[-1] ease-in-out duration-300' />
				</button> */}
				<a href='localhost:3000/home'>
					<img
						src={images.logoPng}
						alt='logo'
						className={`${
							active ? "hidden" : ""
						} h-12 sm:h-24 object-contain rounded-lg backdrop-blur-sm bg-white/30 `}
					/>
				</a>

				{/* <div className='flex flex-row justify-self-end'></div> */}
			</div>
		</div>
	);
};

export default Navigation;
