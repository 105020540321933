import React, { useState } from "react";
import { images } from "../constants";
import { RoadmapModal } from "./";

const Roadmap = () => {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(0);

	return (
		<div id='roadmap' className='flex flex-col h-fit'>
			<div className='relative flex flex-col items-center bg-[#1e1c1c]'>
				{open && (
					<RoadmapModal
						open={open}
						setOpen={setOpen}
						setSelected={setSelected}
						selected={selected}
					/>
				)}

				<p className=' bg-green-800 rounded-t-md backdrop-blur-sm uppercase left-[39%] top-[2%] p-2 text-[12px] lg:text-sm'>
					Click on a Sign to reveal the initial <strong>mint</strong> roadmap
				</p>
				<div className='relative flex flex-col'>
					<img src={images.roadmap} alt='roadmap' className='relative ' />

					<button
						onClick={() => {
							setSelected(0);
							setOpen(true);
						}}
						className='peer/1 absolute flex flex-col items-center justify-center rounded-full z-30 top-[6.5%] left-[28.5%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/1:visible invisible top-[6.5%] left-[31%] text-center text-[1vw] sm:text-md backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float'>
						10%
					</p>
					<button
						onClick={() => {
							setSelected(1);
							setOpen(true);
						}}
						className='absolute peer/2 flex flex-col items-center justify-center rounded-full z-30 top-[30.1%] left-[64.9%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/2:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[30%] left-[67.5%]'>
						20%
					</p>
					<button
						onClick={() => {
							setSelected(2);
							setOpen(true);
						}}
						className='absolute peer/3 flex flex-col items-center justify-center rounded-full z-30 top-[78.2%] left-[93.6%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/3:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[78.2%] left-[96%]'>
						30%
					</p>
					<button
						onClick={() => {
							setSelected(3);
							setOpen(true);
						}}
						className='absolute peer/4 flex flex-col items-center justify-center rounded-full z-30 top-[-0.9%] left-[67.55%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/4:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[-0.9%] left-[70%]'>
						40%
					</p>
					<button
						onClick={() => {
							setSelected(4);
							setOpen(true);
						}}
						className='absolute peer/5 flex flex-col items-center justify-center rounded-full z-30 top-[14.8%] left-[2.9%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/5:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[14.8%] left-[5.3%]'>
						50%
					</p>
					<button
						onClick={() => {
							setSelected(5);
							setOpen(true);
						}}
						className='absolute peer/6 flex flex-col items-center justify-center rounded-full z-30 top-[62.8%] left-[32%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/6:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[62.8%] left-[34.5%]'>
						60%
					</p>
					<button
						onClick={() => {
							setSelected(6);
							setOpen(true);
						}}
						className='absolute peer/7 flex flex-col items-center justify-center rounded-full z-30 top-[19.8%] left-[36.5%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/7:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[19.8%] left-[38.9%]'>
						70%
					</p>
					<button
						onClick={() => {
							setSelected(7);
							setOpen(true);
						}}
						className='absolute peer/8 flex flex-col items-center justify-center rounded-full z-30 top-[82%] left-[73.5%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/8:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[82%] left-[75.8%]'>
						80%
					</p>
					<button
						onClick={() => {
							setSelected(8);
							setOpen(true);
						}}
						className='absolute peer/9 flex flex-col items-center justify-center rounded-full z-30 top-[67%] left-[29%]  h-1/6 w-1/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/9:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[67.3%] left-[31.2%]'>
						90%
					</p>
					<button
						onClick={() => {
							setSelected(9);
							setOpen(true);
						}}
						className='absolute peer/10 flex flex-col items-center justify-center rounded-full z-30 top-[-5%] left-[70.5%]  h-1/6 w-[11%] justify-self-center group cursor-pointer scale-50 ease-in-out duration-150'
					>
						<img
							src={images.hole10_highlight}
							alt='highlight1'
							className='w-[100%] invisible group-hover:visible'
						/>
					</button>
					<p className='absolute peer-hover/10:visible invisible text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 animate-float top-[5%] left-[77%]'>
						100%
					</p>
				</div>
			</div>
		</div>
	);
};

export default Roadmap;
