// external
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useAccount, useSigner, useContractReads } from "wagmi";
// hooks
import { useTx } from "../hooks";
import { getTransactionLink } from "../lib";
//state
import { useRecoilState } from "recoil";
import { selectedNFTsState } from "../atoms/atoms";
import { config } from "../constants";
//components
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { LoadingButton, Switch } from "../primitives/Primitives";
import StakingCard from "./staking/StakingCard";
import Footer from "./Footer";
import NavBar from "./NavBar";

function addressEqual(a, b) {
	return a.toLowerCase() === b.toLowerCase();
}

const fetchUserTokens = async (userAddress, contract, setLoaded) => {
	const sentLogs = await contract.queryFilter(
		contract.filters.Transfer(userAddress, null)
	);
	const receivedLogs = await contract.queryFilter(
		contract.filters.Transfer(null, userAddress)
	);

	const logs = sentLogs
		.concat(receivedLogs)
		.sort(
			(a, b) =>
				a.blockNumber - b.blockNumber || a.transactionIndex - b.transactionIndex
		);
	const owned = new Set();

	for (const log of logs) {
		const { from, to, tokenId } = log.args;

		if (addressEqual(to, userAddress)) {
			owned.add(tokenId.toString());
		} else if (addressEqual(from, userAddress)) {
			owned.delete(tokenId.toString());
		}
	}

	setLoaded(true);
	return owned;
};

const Staking = () => {
	const [owned, setOwned] = useState();
	const [loaded, setLoaded] = useState(false);
	const [fetched, setFetched] = useState(false);
	const [refundFetched, setRefundFetched] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [refund, setRefund] = useState(false);
	const [txHash, setTxHash] = useState("");
	const [transactionProcessing, setTransactionProcessing] = useState(false);
	const [fromToken, setFromToken] = useState(0);

	const perPage = 8;

	const [selectedNFTs, setSelectedNFTs] = useRecoilState(selectedNFTsState);

	const { handleTx, handleTxError } = useTx();
	const { address: userAddress } = useAccount();

	const { data: signer } = useSigner();

	const contractConfig = {
		address: config.contractAddress,
		abi: config.abi,
	};

	const { data: stakingData } = useContractReads({
		contracts:
			owned !== undefined
				? Array.from(owned)?.map((num, _) => ({
						...contractConfig,
						functionName: "playingPeriod",
						args: [num],
				  }))
				: [
						{
							...contractConfig,
							args: [],
						},
				  ],
		watch: true,
	});

	const { data: refundData } = useContractReads({
		contracts:
			owned !== undefined
				? Array.from(owned)?.map((num, _) => ({
						...contractConfig,
						functionName: "checkRefund",
						args: [num],
				  }))
				: [
						{
							...contractConfig,
							args: [],
						},
				  ],
		watch: true,
	});

	const contract = new ethers.Contract(
		config.contractAddress,
		config.abi,
		signer
	);

	const fetch = async () => {
		const _owned = await fetchUserTokens(userAddress, contract, setLoaded);
		setOwned(_owned);
	};

	useEffect(() => {
		if (!loaded) {
			fetch();
		}

		if (stakingData !== undefined) {
			setFetched(true);
		}

		if (refundData !== undefined) {
			setRefundFetched(true);
		}
	}, [loaded, userAddress, fetched, signer, stakingData]);

	const togglePlaying = () => {
		setTransactionProcessing(true);
		contract
			.connect(signer)
			.togglePlaying(selectedNFTs)
			.then(handleTx)
			.then((res) => {
				setTxHash(getTransactionLink(res.transactionHash));
			})
			.catch(handleTxError)
			.finally(() => {
				setTimeout(() => setTransactionProcessing(false), 300);
			});
	};

	const requestRefund = () => {
		setTransactionProcessing(true);
		contract
			.connect(signer)
			.getRefunds(selectedNFTs)
			.then(handleTx)
			.then((res) => {
				setTxHash(getTransactionLink(res.transactionHash));
			})
			.catch(handleTxError)
			.finally(() => {
				setTimeout(() => setTransactionProcessing(false), 300);
			});
	};

	return (
		<div className='bg-green-500 text-white bg-staking bg-no-repeat bg-center'>
			<NavBar />
			<div className='h-full bg-black/80 z-10 '>
				<div className='flex flex-col py-10 w-10/12 h-full mx-auto text-white '>
					<div className='flex flex-row items-center mx-auto'>
						<Switch
							enabled={toggle}
							setEnabled={setToggle}
							onTag={"Not playing"}
							offTag={"Playing"}
							clear={true}
							setSelected={setSelectedNFTs}
						/>
						<Switch
							enabled={refund}
							setEnabled={setRefund}
							onTag={"Refund"}
							offTag={"Staking"}
							clear={true}
							setSelected={setSelectedNFTs}
						/>
					</div>

					<div className='flex flex-row items-center justify-center w-full'>
						<button
							onClick={() => {
								setFromToken((prevValue) =>
									prevValue === 0 ? prevValue : prevValue - 1
								);
							}}
							disabled={fromToken === 0}
						>
							<MdKeyboardArrowLeft
								className={`${
									fromToken > 0 ? "visible" : "invisible"
								} w-[7vw] h-[7vw] hover:text-green-500 text-white`}
							/>
						</button>

						<div className='bg-black/60 h-fit p-4 rounded-lg w-full my-2 min-h-[70vh]'>
							<h2 className='text-xl pb-3'>
								{refund ? "Refund" : !toggle ? "Playing" : "Not playing"}
							</h2>
							<hr className='bg-white' />
							<div className='w-full mt-2 mb-2'>
								{fetched && !refund ? (
									<div className='grid grid-cols-1 550:grid-cols-3 md:grid-cols-4 grid-rows-2'>
										{!toggle && stakingData?.length > 0
											? stakingData
													?.filter((item, _) => item.playing)
													.filter(
														(_, index) =>
															index >= fromToken * perPage &&
															index < fromToken * perPage + perPage
													)
													.map((el, idx) => (
														<StakingCard
															key={idx}
															total={el.total}
															index={el.id}
															stake={true}
														/>
													))
											: toggle &&
											  stakingData?.length > 0 &&
											  stakingData
													?.filter((item, _) => !item.playing)
													.filter(
														(_, index) =>
															index >= fromToken * perPage &&
															index < fromToken * perPage + perPage
													)
													.map((el, idx) => (
														<StakingCard
															key={idx}
															total={el.total}
															index={el.id}
															stake={true}
														/>
													))}
									</div>
								) : refundFetched && refund ? (
									<div className='grid grid-cols-1 550:grid-cols-3 md:grid-cols-4 grid-rows-2'>
										{refundData?.length > 0 ? (
											refundData
												?.map((isRefundable, index) => ({
													refundable: isRefundable,
													id: Array.from(owned)[index],
												}))
												.filter((el, _) => el.refundable === true)
												.map((el, idx) => (
													<StakingCard
														key={idx}
														total={0}
														index={el.id}
														stake={false}
													/>
												))
										) : (
											<div className='w-fit text-center self-center'>
												No refundable NFTs found.
											</div>
										)}
									</div>
								) : (
									<div>Fetching...</div>
								)}
							</div>
						</div>
						<button
							onClick={() => {
								setFromToken((prevValue) =>
									prevValue === owned?.length % perPage
										? prevValue
										: prevValue + 1
								);
							}}
							disabled={
								stakingData?.filter((item, _) => {
									if (toggle) {
										return !item.playing;
									} else {
										return item.playing;
									}
								}).length <
								fromToken * perPage + perPage
							}
						>
							<MdKeyboardArrowRight
								className={`${
									stakingData?.filter((item, _) => {
										if (toggle) {
											return !item.playing;
										} else {
											return item.playing;
										}
									}).length >
									fromToken * perPage + perPage
										? "visible"
										: "invisible"
								} w-[7vw] h-[7vw] hover:text-green-500 text-white`}
							/>
						</button>
					</div>

					{!refund && toggle ? (
						<LoadingButton
							onClick={() => {
								togglePlaying();
								setSelectedNFTs([]);
							}}
							loading={transactionProcessing}
							className='min-w-[100px] my-2 bg-green-500 rounded-md self-center'
						>
							Send to course
						</LoadingButton>
					) : !refund && !toggle ? (
						<LoadingButton
							onClick={() => {
								togglePlaying();
								setSelectedNFTs([]);
							}}
							loading={transactionProcessing}
							className='min-w-[100px] my-2 bg-green-500 rounded-md self-center'
						>
							Come back home
						</LoadingButton>
					) : (
						refund && (
							<LoadingButton
								onClick={() => {
									requestRefund();
									setSelectedNFTs([]);
								}}
								loading={transactionProcessing}
								className='min-w-[100px] my-2 bg-green-500 rounded-md self-center'
							>
								Refund
							</LoadingButton>
						)
					)}
				</div>
			</div>
			<hr className='bg-white' />

			<Footer />
		</div>
	);
};

export default Staking;
