import React from "react";
import cn from "classnames";
import Link from "react-scroll/modules/components/Link";
import { Link as LinkRouter } from "react-router-dom";
import content from "../content";
import { images } from "../constants";

const Footer = ({ className }) => {
	return (
		<div
			className={cn(
				"relative z-10 flex flex-col bg-black/80 h-fit pb-2 overflow-hidden sm:overflow-visible  mx-auto bg-black pt-1",
				className
			)}
		>
			<div className='flex flex-col 900:flex-row justify-between items-center w-10/12 h-full mx-auto text-white'>
				<div className='flex flex-col 500:flex-row items-center h-full w-full'>
					<a href='https://golf-test.netlify.app/'>
						<img
							src={images.logoPng}
							alt='logo'
							className='h-24 object-contain rounded-lg bg-white p-2 500:mb-0'
						/>
					</a>
					<div className='block 500:hidden h-[1px] w-full bg-white/60 my-5'></div>
					<div className='flex flex-col 500:flex-row mx-auto 500:mx-0 500:ml-auto'>
						<div className='flex flex-row justify-center my-auto mr-2'>
							{content.nav.social.map((item, index) => (
								<a
									key={index}
									href={item.link}
									target='_blank'
									rel='noreferrer'
									className='rounded-full bg-white mr-2 w-7 h-7 flex items-center justify-center hover:ring-1 ring-white'
								>
									{item.icon}
								</a>
							))}
						</div>
						<div className='block 500:hidden h-[1px] w-full bg-white/60 my-5'></div>

						<div className='w-[1px] bg-white/60 500:block hidden h-[90px] '></div>
						<div className='flex flex-row 500:flex-col 500:ml-2 mb-5 500:mb-0 '>
							{content.nav.links.map((el, idx) => {
								if (el.scroll) {
									return (
										<Link
											key={idx}
											to={el.to}
											smooth={true}
											className='text-[12px] cursor-pointer hover:font-bold uppercase mx-auto mx-2'
										>
											{el.text}
										</Link>
									);
								} else {
									return (
										<LinkRouter
											key={idx}
											to={el.to}
											className='text-[12px] cursor-pointer hover:font-bold uppercase mx-auto mx-2'
										>
											{el.text}
										</LinkRouter>
									);
								}
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
