import React, { useState, useEffect } from "react";
import { images } from "../constants";

const Loader = ({ loaded }) => {
	const [progress, setProgress] = useState(1);

	useEffect(() => {
		setTimeout(() => {
			setProgress(100);
		}, 2700);
	}, [progress]);

	return (
		<div
			className={`${
				loaded ? "hidden" : "flex"
			} flex-col z-50 h-screen w-screen items-center justify-center bg-black`}
		>
			<img
				src={images.logoPng}
				alt='loading'
				className='animate-float ease-in-out duration-100 h-1/6 rounded-lg mb-5 bg-white backdrop-blur-sm'
			/>
			<div className='w-2/3 bg-gray-200 h-1 rounded-lg'>
				<div
					className='bg-blue-600 h-1 rounded-lg ease-in-out animate-fill origin-bottom-left delay-100'
					style={{ width: `${progress}%` }}
				></div>
			</div>
		</div>
	);
};

export default Loader;
