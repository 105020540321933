import React, { useState, useEffect } from "react";

const Slide = ({ images }) => {
	const [selected, setSelected] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setSelected((prevState) =>
				prevState === images.length - 1 ? 0 : prevState + 1
			);
		}, 3000);
	}, [selected, images.length]);
	return (
		<img
			src={images[selected]}
			alt='team'
			className='w-64 rounded-md object-contain my-5 md:my-0 md:mx-auto lg:mr-20'
		/>
	);
};

export default Slide;
