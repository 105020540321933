import React from "react";
import { images } from "../constants";
import { Link } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

const Clubhouse = () => {
	return (
		<div id='home' className='flex flex-col '>
			<div className='relative flex flex-col items-center bg-[#5a6355]'>
				<div className='relative flex flex-col'>
					<img src={images.clubhouse} className='relative peer' />
					<p className='absolute top-[2%] right-[8%] bg-black/40 rounded-lg p-2 z-30 backdrop-blur-sm animate-float text-[6px] 500:text-[8px] sm:text-sm md:text-md'>
						Click on items to interact
					</p>

					<LinkRouter
						to='/mint'
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[67.5%] left-[47.3%] h-1/6 w-1/6 justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'
					>
						<p className='absolute text-center text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-10%] left-[40%] invisible group-hover:visible animate-float'>
							🚧 Mint 🚧
						</p>
						<img
							src={images.logo_highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</LinkRouter>
					<LinkRouter
						to='/staking'
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[81.6%] left-[56.6%] h-1/6 w-1/6 justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'
					>
						<p className='absolute text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-10%] left-[40%] invisible group-hover:visible animate-float'>
							🚧 Staking 🚧
						</p>
						<img
							src={images.desk_highlight}
							alt='highlight1'
							className='h-[87%] invisible group-hover:visible'
						/>
					</LinkRouter>
					<Link
						to='roadmap'
						smooth={true}
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[57.65%] left-[79.35%] h-1/6 w-1/6  justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'
					>
						<p className='absolute text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-30%] left-[40%] invisible group-hover:visible animate-float'>
							Roadmap
						</p>
						<img
							src={images.easle_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</Link>
					<a
						href='https://medium.com/@cryptokeepr/the-mvcc-story-b75f3d9d7d7'
						target='_blank'
						rel='noreferrer'
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[34.5%] left-[41.01%] h-[10%] w-[10%]  justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150 '
					>
						<p className='absolute text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-30%] left-[40%] invisible group-hover:visible animate-float'>
							Article
						</p>
						<img
							src={images.papers_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</a>

					<a
						href='https://discord.gg/sNU8Sqdg'
						target='_blank'
						rel='noreferrer'
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[18.17%] left-[68.37%] h-[10%] w-[10%] justify-self-center group cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'
					>
						<p className='absolute text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-30%] left-[30%] invisible group-hover:visible animate-float'>
							Discord
						</p>
						<img
							src={images.discord_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</a>
					<a
						href='https://twitter.com/MVCC_ETH'
						target='_blank'
						rel='noreferrer'
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[30.75%] left-[68.45%] h-[10%] w-[10%] justify-self-center group cursor-pointer scale-50 ease-in-out hover:scale-100 duration-150 '
					>
						<p className='absolute text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-40%] left-[35%] invisible group-hover:visible animate-float'>
							Twitter
						</p>
						<img
							src={images.twitter_highlight}
							alt='highlight1'
							className='h-[150%] invisible scale-50 group-hover:scale-100 group-hover:visible '
						/>
					</a>
					<a
						href='localhost.com'
						target='_blank'
						rel='noreferrer'
						className='absolute text-[1vw] flex flex-col items-center justify-center rounded-full z-30 top-[28.3%] left-[56.3%] h-[10%] w-[10%] justify-self-center group cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'
					>
						<p className='absolute backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-38%] left-[20%] invisible group-hover:visible animate-float'>
							Opensea
						</p>
						<img
							src={images.opensea_highlight}
							alt='highlight1'
							className='h-[88%] invisible group-hover:visible'
						/>
					</a>
					<a
						href='localhost.com'
						target='_blank'
						rel='noreferrer'
						className='absolute flex flex-col items-center justify-center rounded-full z-30 top-[33.35%] left-[61.45%] h-[10%] w-[10%] justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'
					>
						<p className='absolute text-[1vw] backdrop-blur-sm bg-black/40 rounded-lg p-2 top-[-34%] left-[20%] invisible group-hover:visible animate-float'>
							LooksRare
						</p>
						<img
							src={images.looksrare_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Clubhouse;
