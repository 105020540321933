import walletlink from "../assets/images/WalletLink.svg";
import walletconnect from "../assets/images/WalletConnect.svg";
import MetaMask from "../assets/images/MetaMask.svg";
import metamask from "../assets/images/metamask.png";
import discord from "../assets/logos/discord.svg";
import opensea from "../assets/logos/opensea.svg";
import twitter from "../assets/logos/twitter.svg";
import teamPlaceholder from "../assets/images/team_placeholder.jpg";
import placeholder from "../assets/images/placeholder.jpeg";
import etherscan from "../assets/logos/etherscan.svg";
import logo from "../assets/logos/golf_logo.jpeg";
import logoPng from "../assets/logos/logo-transparent.png";
import header from "../assets/images/golf_header-min.png";
import clubhouse from "../assets/images/clubhouse1.png";
import sign1 from "../assets/images/sign1.png";
import sign2 from "../assets/images/sign2.png";
import sign3 from "../assets/images/sign3.png";
import sign4 from "../assets/images/sign4.png";
import sign5 from "../assets/images/sign5.png";
import sign6 from "../assets/images/sign6.png";
import sign7 from "../assets/images/sign7.png";
import sign8 from "../assets/images/sign8.png";
import sign9 from "../assets/images/sign9.png";
import sign10 from "../assets/images/sign10.png";
import roadmap from "../assets/images/Roadmap-min.png";
import staking from "../assets/images/staking.png";
import highlight from "../assets/images/highlight.png";
import desk_highlight from "../assets/images/FrontDesk.png";
import opensea_highlight from "../assets/images/clubhouse_opensea.png";
import discord_highlight from "../assets/images/clubhouse_discord.png";
import looksrare_highlight from "../assets/images/LooksRare.png";
import twitter_highlight from "../assets/images/Twitter_Stand.png";
import papers_highlight from "../assets/images/Papers.png";
import hole10_highlight from "../assets/images/hole10_outline.png";
import logo_highlight from "../assets/images/clubhouse_highlight.png";
import easle_highlight from "../assets/images/Easle.png";
import mint from "../assets/images/mint.jpg";
import team1 from "../assets/images/team1.jpeg";
import team2 from "../assets/images/team2.jpeg";
import team3 from "../assets/images/team3.jpeg";
import team4 from "../assets/images/team4.png";
import partners from "../assets/images/mvccpartnerwelcome.jpg";

export default {
	walletlink,
	walletconnect,
	metamask,
	MetaMask,
	discord,
	twitter,
	opensea,
	teamPlaceholder,
	placeholder,
	etherscan,
	logo,
	header,
	clubhouse,
	sign1,
	sign2,
	sign3,
	sign4,
	sign5,
	sign6,
	sign7,
	sign8,
	sign9,
	sign10,
	roadmap,
	logoPng,
	staking,
	highlight,
	desk_highlight,
	papers_highlight,
	logo_highlight,
	hole10_highlight,
	opensea_highlight,
	discord_highlight,
	looksrare_highlight,
	twitter_highlight,
	easle_highlight,
	mint,
	team1,
	team2,
	team3,
	team4,
	partners,
};
