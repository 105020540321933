import React, { useState, useEffect } from "react";
import { Card } from ".";
import { images } from "../constants";
import content from "../content";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Slide from "./Slide";

const Team = () => {
	const [selected, setSelected] = useState(0);
	const teamImages = [
		content.team[0].image,
		content.team[1].image,
		content.team[2].image,
		content.team[3].image,
	];

	return (
		<div className='flex flex-col items-center mx-auto bg-[#1e1c1c]'>
			<div id='project' className='flex flex-col h-fit mx-auto text-md w-10/12'>
				<div className='flex flex-row justify-between mt-10 mb-10'>
					<div className='max-w-full md:max-w-[60%]'>
						<h2 className='uppercase text-4xl font-bold mb-2'>THE TEAM</h2>

						{/* <p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
						</p> */}
						<div className='flex md:hidden'>
							<Slide images={teamImages} />
						</div>
						{content.team.map((item, index) => (
							<div key={index} className='mt-5'>
								<p className='mb-2'>
									<span className='text-xl font-bold mr-2'>{item.name}</span>{" "}
									<span className='text-lg text-green-400'>{item.title}</span>
								</p>
								<div className='flex flex-row '>
									{item.description.map((i, idx) => (
										<p
											key={idx}
											className='p-2 mr-2 rounded-md text-sm md:text-[16px] bg-white/80 backdrop-blur-sm text-black'
										>
											{i}
										</p>
									))}
								</div>
							</div>
						))}
					</div>
					<div className='hidden items-center justify-center md:flex'>
						<Slide images={teamImages} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Team;
