import React, { useState } from "react";
import { Card } from "./";
import { images } from "../constants";
import content from "../content";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const Project = () => {
	const [selected, setSelected] = useState(0);

	return (
		<div className='flex flex-col items-center mx-auto bg-[#1e1c1c]'>
			<div
				id='project'
				className='flex flex-col min-h-screen h-fit mx-auto text-md w-10/12'
			>
				<div className='flex flex-col sm:flex-row justify-between mt-10 mb-10'>
					<div className='sm:max-w-[60%]'>
						<h2 className='uppercase text-4xl font-bold mb-2'>
							METAVERSE COUNTRY CLUB
						</h2>
						<h4 className='text-sm sm:text-[16px] italic mb-4 text-green-400'>
							Creating a new path one bogey at a time.
						</h4>
						<p className='text-sm sm:text-[16px] sm:leading-6 text-justify'>
							MVCC is an organization of golfers that are competing against one
							another in the NFTour. Each golfer has a unique set of physical
							and skill traits that are programmed into the Ethereum blockchain,
							providing a randomized and distributed set of attributes. There
							are over 160 traits combined, allowing for a diverse and
							challenging golfing experience. No matter where you are - on a
							yacht with apes, at the art blocks factory, or in the gutter with
							cats - NFTs have a big impact on the community and help to create
							a culture among those who interact with them. Every golfer is
							stored on the Ethereum network as an ERC-721 token, granting the
							owner full ownership and commercial rights over their NFT
							(Non-fungible Token)
						</p>
					</div>
					<div className='flex flex-col items-center justify-center backdrop-blur-sm bg-green-400/60 rounded-lg mt-5 sm:mt-0'>
						<img
							src={images.logoPng}
							alt='MVCC logo'
							className='w-[200px] object-contain rounded-lg animate-rotate'
						/>
					</div>
				</div>
				<div className='h-[1px] w-full bg-white/40'></div>
				<div className='flex flex-col sm:flex-row mb-10 mt-10'>
					<div className='sm:max-w-[60%]'>
						<h3 className='uppercase text-3xl mb-4 self-start font-bold justify-self-start'>
							JOIN THE CLUB
						</h3>
						<h3 className=' text-xl mt-4 mb-2 self-start text-green-400 justify-self-start'>
							Skill Traits
						</h3>
						<p className='text-sm sm:text-[16px] sm:leading-6 text-justify'>
							When minting a golfer, users are given four Skill Traits to
							customize: Golf IQ, Luck, Avg. Driving Distance, and Short Game.
							The minter is allocated twelve skill points, allowing users to
							create a unique golfer with the perfect combination of traits. Do
							you want to hit bombs off the tee box but risk the potential for a
							nasty ball-in-the-woods penalty? Or do you want to play it safe
							and focus on accuracy and finesse? With MVCC, the choice is yours.
						</p>
						<h3 className=' text-xl mt-4 mb-2 self-start text-green-400  justify-self-start'>
							Practice at the Range
						</h3>
						<p className='text-sm sm:text-[16px] sm:leading-6 text-justify'>
							Players can stake their golfers at the “Driving Range” to unlock
							rewards and benefits. The longer you spend at the range, the more
							rewards you will receive. Those who spend the most time at the
							range will be rewarded with exclusive discounts from partnered
							companies, exclusive merchandise, and more! So, if you're looking
							to get an edge on the competition and maximize your rewards, the
							Driving Range is the place to be!
						</p>
					</div>
					<div className='mx-auto sm:mx-0 sm:ml-auto my-auto pt-5 sm:pl-5 sm:max-w-[40%]'>
						<img
							src={images.partners}
							alt='Project Partners'
							className='w-full object-contain rounded-lg '
						/>
					</div>
				</div>

				<div className='h-[1px] w-full bg-white/40'></div>

				<div className='flex flex-row mt-10 mb-10'>
					<div className='sm:max-w-[60%]'>
						<h3 className='uppercase text-3xl mb-4 self-start font-bold justify-self-start'>
							What's next?
						</h3>
						<p className='text-sm sm:text-[16px] sm:leading-6 text-justify'>
							MVCC is creating a golf game that allows players to tee off and
							compete in tournaments on the Ethereum blockchain. Players can
							purchase and customize unique golf clubs, balls, and skill traits,
							and play on exclusive courses, each with their own challenges and
							obstacles. They can then compete in tournaments, individual
							rounds, and minigames against other players to win rewards and
							gain recognition. With MVCC, players can experience thrilling
							golfing with the security and transparency of blockchain
							technology.
						</p>
					</div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default Project;
