const errors = {
	"0x303b682f": "Unable to mint: You have reached the mint limit per wallet.",
	"0xdbbbe822": "Unable to mint: You have not provided enough ETH to mint.",
	"0xd05cb609": "Unable to mint: The collection has been minted out.",
	"0x479290cf":
		"Unable to mint: Tried minting too many NFTs at once. Please reduce the amount.",
	"0x1ceedf60": "Unable to mint: The sale has not started yet.",
	"0x551a40dc": "Refund is unavailable.",
	"0x6c97757c": "Skill choice is invalid.",
	"0x1e042eae":
		"Playing is not allowed right now or your NFT is already playing.",
	"0xff633a38": "Token quantity and stat selection does not match.",
	"0x270b168d": "Unable to mint: Stats provided are over the limit.",
};

export default errors;
