import React, { useEffect, useState } from "react";
import { Navigation, Clubhouse, Roadmap, Team, Footer } from "./";
import Loader from "./Loader";
import Project from "./Project";

const Home = () => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoaded(true);
		}, 3000);
	}, []);

	if (!loaded) {
		return <Loader loaded={loaded} />;
	}

	return (
		<div className='bg-green-500 text-white'>
			<Navigation sticky={true} />
			<Clubhouse />
			<Project />
			<Roadmap />
			<Team />
			<Footer />
		</div>
	);
};

export default Home;
