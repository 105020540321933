// eslint-disable-next-line import/no-anonymous-default-export
import { images } from "../constants";
import {
	RiFacebookFill,
	RiGoogleFill,
	RiTwitterFill,
	RiInstagramLine,
	RiDiscordFill,
} from "react-icons/ri";

const content = {
	nav: {
		links: [
			{ text: "Home", to: "home", icon: images.discord, scroll: true },

			{ text: "Roadmap", to: "roadmap", icon: images.twitter, scroll: true },
			{ text: "Team", to: "team", icon: images.opensea, scroll: true },
			{ text: "Mint", to: "/mint", icon: images.opensea, scroll: false },
			{ text: "Staking", to: "/staking", icon: images.opensea, scroll: false },
		],
		social: [
			{
				icon: (
					<img
						src={images.opensea}
						alt='opensea'
						className='h-10 w-10 p-2 text-[#28299f]'
					/>
				),
				link: "https://opensea.io/",
				alt: "opensea",
			},
			{
				icon: (
					<img
						src={images.etherscan}
						alt='opensea'
						className='h-10 w-10 p-2 text-[#28299f]'
					/>
				),
				link: "https://etherscan.io/",
				alt: "etherscan",
			},
			{
				icon: <RiTwitterFill className='h-10 w-10 p-2 text-[#28299f]' />,
				link: "https://twitter.com/MVCC_ETH",
				alt: "twitter",
			},
			/* {
				icon: <RiInstagramLine className='h-10 w-10 p-2 text-[#28299f]' />,
				link: "https://instagram.com/",
				alt: "instagram",
			}, */
			{
				icon: <RiDiscordFill className='h-10 w-10 p-2 text-[#28299f]' />,
				link: "https://discord.gg/sNU8Sqdg",
				alt: "discord",
			},
		],
	},

	team: [
		{
			name: "InternDrugDealer",
			title: "Founder/President",
			description: ["Avid three putter", "8-Handicap", "2 Iron > 3 Wood"],
			image: images.team1,
			socials: {
				twitter: "https://twitter.com/",
				instagram: "https://www.instagram.com/",
			},
		},
		{
			name: "MrMamba",
			title: "Founder/Artist",
			description: ["Lefty", "20-Handicap", "Enjoys playing in the sand"],

			image: images.team2,
			socials: {
				twitter: "https://twitter.com/",
				instagram: "https://www.instagram.com/",
			},
		},
		{
			name: "TheDev",
			title: "Developer",
			description: [
				"Entertained by driving the golf cart",
				"69-Handicap",
				"Loves the texas wedge",
			],

			image: images.team3,
			socials: {
				twitter: "https://twitter.com/",
				instagram: "https://www.instagram.com/",
			},
		},
		{
			name: "CollectorKev",
			title: "Marketing/Community Leader",
			description: ["Always has an extra tee", "30 Handicap", "Fairway Finder"],
			image: images.team4,
			socials: null,
		},
	],
	stats: {
		statTitles: ["Golf IQ", "Luck", "Avg. Driving Distance", "Short Game"],
		skillTitles: ["Club Preference", "Play Style"],
		statTraits: {
			"Golf IQ": [
				"In Shambles",
				"Bleak",
				"Drives for Show",
				"Fairway Finder",
				"Stinger Specialist",
				"Dialed In",
			],
			Luck: [
				"YHTSI",
				"Lipout Connoisseur",
				"Wears Lucky Underwear",
				"Cart Path Help",
				"Pinseeker",
				"Tree Assistance",
			],
			"Avg. Driving Distance": ["187", "202", "219", "242", "269", "286"],
			"Short Game": [
				"Ball Topper",
				"Chili Dipper",
				"Texas Wedge Pro",
				"Bump n' Run Loyalist",
				"Bunker Warrior",
				"Flop Shot Expert",
			],
		},
		skillTraits: {
			"Club Preference": ["Driver", "Irons", "Wedges", "Putter"],
			"Play Style": [
				"Energizer",
				"Technician",
				"Magician",
				"Aggressor",
				"Strategist",
			],
		},
		descriptions: {
			"Golf IQ":
				"This trait measures the player's ability to make decisions and execute strategies on the golf course. It includes factors such as course management, club selection, reading greens, and shot selection. ",
			Luck: "This trait measures the player's ability to have a bit of luck on their side when playing the game. It includes factors such as being able to hit lucky shots, getting lucky bounces, and having strokes of luck when playing. Having a bit of luck can help players get out of difficult situations and lower their scores.",
			"Avg. Driving Distance":
				"This trait measures the player's ability to hit the golf ball a long distance off the tee.  It includes factors such as club selection, swing speed, and technique. Having a high average driving distance can help players hit longer drives, giving them better positioning on the course.",
			"Short Game":
				"This trait measures the player's ability to execute shots around the green. It includes factors such as chipping, pitching, and putting. Having a high short game can help players get the ball close to the hole on their approach shots, giving them a better chance of making the putt.",
			"Club Preference":
				"This trait measures the player's ability to choose the right club for any given situation. It includes factors such as knowledge of the clubs, preference for certain clubs, and the ability to read the course.",
			"Play Style":
				"This trait measures the player's ability to adjust their strategy to fit any situation.  It includes factors such as the ability to read the course, and play aggressively when needed. Whether you're a magician with the clubs or a strategist on the course, it's important to make your mark.",
		},
	},
};

export default content;
