import React from "react";
import { images } from "../../constants";
import { AiOutlineClose } from "react-icons/ai";
import content from "../../content";
import Link from "react-scroll/modules/components/Link";
import { Link as LinkRouter } from "react-router-dom";

const Menu = ({ menuOpen, setMenuOpen }) => {
	return (
		<div
			className={`absolute max-w-[1500px] bg-black/70 p-6 top-0 -left-full h-screen w-screen ease-in-out duration-500 bg-opacity-90 backdrop-blur-sm ${
				menuOpen ? "flex flex-col translate-x-full" : "opacity-0"
			}`}>
			<button
				onClick={() => {
					setMenuOpen(false);
				}}>
				<AiOutlineClose className='w-10 h-10' />
			</button>
			<div className='flex flex-row items-center justify-between ml-10'>
				<div className='flex flex-col'>
					{content.nav.links.map((item, index) =>
						item.scroll ? (
							<Link
								key={index}
								to={item.to}
								smooth={true}
								className='p-3 text-3xl  cursor-pointer hover:translate-x-2 hover:text-orange-400 ease-in-out duration-100'>
								{item.text}
							</Link>
						) : (
							<LinkRouter
								key={index}
								to={item.to}
								className='p-3 text-3xl cursor-pointer hover:translate-x-2 hover:text-orange-400 ease-in-out duration-100'>
								{item.text}
							</LinkRouter>
						)
					)}
				</div>
				<div className='relative flex flex-col items-end mr-10 w-4/6'>
					<img
						src={images.clubhouse}
						alt='Clubhouse interior'
						className='rounded-lg w-full hidden sm:block'
					/>
					<Link
						to='home'
						smooth={true}
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[67.5%] left-[47.3%] h-1/6 w-1/6 justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'>
						<img
							src={images.logo_highlight}
							alt='highlight1'
							className='h-[77%] invisible group-hover:visible'
						/>
					</Link>
					<Link
						to='somewhere'
						smooth={true}
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[81.6%] left-[56.6%] h-1/6 w-1/6 justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'>
						<img
							src={images.desk_highlight}
							alt='highlight1'
							className='h-[87%] invisible group-hover:visible'
						/>
					</Link>
					<Link
						to='roadmap'
						smooth={true}
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[57.65%] left-[79.35%] h-1/6 w-1/6  justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'>
						<img
							src={images.easle_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</Link>
					<Link
						to='team'
						smooth={true}
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[34.5%] left-[41.01%] h-[10%] w-[10%]  justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150 '>
						<img
							src={images.papers_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</Link>

					<a
						href='localhost.com'
						target='_blank'
						rel='noreferrer'
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[18.17%] left-[68.37%] h-[10%] w-[10%] justify-self-center group cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'>
						<img
							src={images.discord_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</a>
					<a
						href='localhost.com'
						target='_blank'
						rel='noreferrer'
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[30.75%] left-[68.45%] h-[10%] w-[10%] justify-self-center group cursor-pointer scale-50 ease-in-out hover:scale-100 duration-150 '>
						<img
							src={images.twitter_highlight}
							alt='highlight1'
							className='h-[150%] invisible scale-50 group-hover:scale-100 group-hover:visible '
						/>
					</a>
					<a
						href='localhost.com'
						target='_blank'
						rel='noreferrer'
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[28.3%] left-[56.3%] h-[10%] w-[10%] justify-self-center group cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'>
						<img
							src={images.opensea_highlight}
							alt='highlight1'
							className='h-[88%] invisible group-hover:visible'
						/>
					</a>
					<a
						href='localhost.com'
						target='_blank'
						rel='noreferrer'
						className='absolute hidden sm:flex flex-col items-center justify-center rounded-full z-10 top-[33.35%] left-[61.45%] h-[10%] w-[10%] justify-self-center group  cursor-pointer scale-50 hover:scale-100 ease-in-out duration-150'>
						<img
							src={images.looksrare_highlight}
							alt='highlight1'
							className='h-[150%] invisible group-hover:visible'
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Menu;
