import React, { useEffect, useState } from "react";
import cn from "classnames";
import Portal from "./Portal";

const Modal = ({ children, open, setOpen, onClose, className, ...props }) => {
	const handleClick = (event) => {
		if (event.target === event.currentTarget) setOpen(false);
	};

	useEffect(() => {
		document.documentElement.style.overflow = "hidden";
		return () => {
			document.documentElement.style.overflow = "overlay";
		};
	}, [open]);

	return (
		<Portal>
			<div
				className={cn(
					"flex justify-center items-center fixed left-0 w-screen h-screen top-0 z-20 overflow-hidden",
					"bg-gray-800/40 backdrop-blur-sm blur-xxl",
					"transition-colors duration-500 animate__animated",
					open ? "animate__fadeIn" : "animate__fadeOut",
					className
				)}
				onClick={handleClick}
				onAnimationEnd={() => {
					if (!open) onClose();
				}}
				{...props}
			>
				<div className='fixed w-full flex items-center'>{children}</div>
			</div>
		</Portal>
	);
};

export default Modal;
