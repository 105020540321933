import React, { useEffect } from "react";
import { Modal } from "../primitives";
import { images } from "../constants";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const RoadmapModal = ({ open, setOpen, selected, setSelected }) => {
	const imagesArr = [
		images.sign1,
		images.sign2,
		images.sign3,
		images.sign4,
		images.sign5,
		images.sign6,
		images.sign7,
		images.sign8,
		images.sign9,
		images.sign10,
	];

	useEffect(() => {
		const handleArrowKeys = (event) => {
			if (event.key === "ArrowLeft") {
				if (selected !== 0) {
					setSelected((prevValue) => prevValue - 1);
				}
				console.log("Left, selected:", selected);
			}
			if (event.key === "ArrowRight") {
				if (selected !== 9) {
					setSelected((prevValue) => prevValue + 1);
					console.log("Increased");
				}
				console.log("Right");
			}
		};
		window.addEventListener("keydown", handleArrowKeys);

		return () => {
			window.removeEventListener("keydown", handleArrowKeys);
		};
	}, [selected]);

	return (
		<Modal
			onClose={() => {
				setOpen(false);
				console.log("Should close");
			}}
			open={open}
			setOpen={setOpen}
			className='p-2 max-w-full z-40'
		>
			<div className='flex flex-col md:flex-row items-center justify-center mx-auto'>
				<button
					onClick={() => {
						if (selected > 0) {
							setSelected((prevValue) => prevValue - 1);
						}
					}}
					className='hidden md:flex flex-row items-center justify-center text-white m-4'
				>
					<AiOutlineArrowLeft className='w-10 h-10' />
					<h2>Previous Hole</h2>
				</button>
				<img
					src={imagesArr[selected]}
					alt=''
					className='flex rounded-lg max-w-[85vw] sm:max-w-[70vw] max-h-[70vh]'
				/>
				<button
					onClick={() => {
						if (selected < 9) {
							setSelected((prevValue) => prevValue + 1);
							console.log("Increased");
						}
					}}
					className='hidden md:flex flex-row items-center justify-center text-white m-4'
				>
					<h2>Next Hole</h2>
					<AiOutlineArrowRight className='w-10 h-10' />
				</button>
				<div className='flex flex-row items-center justify-center'>
					<button
						onClick={() => {
							if (selected > 0) {
								setSelected((prevValue) => prevValue - 1);
							}
						}}
						className='flex md:hidden flex-row items-center justify-center text-white m-4'
					>
						<AiOutlineArrowLeft className='w-10 h-10' />
						<h2>Previous Hole</h2>
					</button>
					<button
						onClick={() => {
							if (selected < 9) {
								setSelected((prevValue) => prevValue + 1);
							}
						}}
						className='flex md:hidden flex-row items-center justify-center text-white m-4'
					>
						<h2>Next Hole</h2>
						<AiOutlineArrowRight className='w-10 h-10' />
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default RoadmapModal;
