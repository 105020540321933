import { abi } from "../abi/MVCC";

const contractAddress = "0xa8772E5F21Fc2eD74A6076292Ca973A24B973F60"; // OLD ADDRESS:0x95054341eD0EF63cb0B0f3645e477c780bAb2Ae2
const chainId = 5;
const networkName = "Goerli Testnet";

export default {
	contractAddress,
	chainId,
	networkName,
	abi,
};
