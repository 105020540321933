import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { Navigation } from "./";
import { AiOutlineArrowDown } from "react-icons/ai";

const Landing = () => {
	return (
		<div className='bg-green-500 text-white'>
			<img
				src={images.header}
				alt=''
				className='z-0 min-h-screen 320:object-cover sm:object-fill'
			/>
			<Navigation />
			<Link
				to='/home'
				onClick={() => {}}
				className='peer absolute  flex flex-col items-center justify-center rounded-full z-10 top-[13%] left-[8%] h-4/6 w-full md:w-11/12 justify-self-center group cursor-pointer scale-50 ease-in-out duration-150 '
			></Link>
			<div className='absolute flex flex-col items-center justify-center top-[30%] left-[2%] w-full animate-float'>
				<h2 className=' z-10 top-[32%] left-[47%] p-2 bg-black/80 rounded-lg'>
					Enter the clubhouse
				</h2>
				<AiOutlineArrowDown className=' z-10 top-[37%] left-[50.7%] text-white w-10 h-10' />
			</div>
		</div>
	);
};

export default Landing;
