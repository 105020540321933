import { useState, useEffect, cloneElement } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, ...props }) => {
	const [portal, setPortal] = useState(null);

	const onClose = () => setPortal(null);

	useEffect(() => {
		const portal = document.createElement("div");
		document.body.appendChild(portal);
		setPortal(portal);
		return () => document.body.removeChild(portal);
	}, []);

	return portal && createPortal(cloneElement(children, { onClose }), portal);
};

export default Portal;
